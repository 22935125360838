@use '../../../../libs/it-ng-components/core/style/vars';

@mixin resets() {
  html,
  body {
    height: 100%;
  }

  html {
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    font-family: vars.$vmr-fonts-base;
  }

  // START: Material table overrides
  .mat-mdc-header-row {
    --mat-table-header-container-height: 52px;
  }

  .mat-mdc-row {
    --mat-table-row-item-container-height: 48px;
  }
  // END: Material table overrides

  // START: ng-doc style overrides
  h1,
  h2,
  h3,
  h4 {
    &.ngde {
      --ng-doc-font-weight: 500;
      font-family: var(--vermeer-font-header);
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    &.ngde {
      margin: calc(var(--ng-doc-base-gutter) * 6) 0 0;
    }
  }

  h1 {
    &.ngde {
      --ng-doc-font-size: 38px;
    }
  }

  h2 {
    &.ngde {
      --ng-doc-font-size: 32px;
      --ng-doc-line-height: calc(var(--ng-doc-base-gutter) * 4.5);

      code {
        &,
        &.ngde {
          font-size: 28px;
          line-height: var(--ng-doc-line-height);
        }
      }
    }

    &#usage {
      padding-bottom: 3px;
      border-bottom: 1px solid var(--ng-doc-base-4);
    }
  }

  h3 {
    &.ngde {
      --ng-doc-font-size: 25px;
      --ng-doc-line-height: calc(var(--ng-doc-base-gutter) * 3.75);

      code {
        &,
        &.ngde {
          font-size: 21px;
          line-height: var(--ng-doc-line-height);
        }
      }
    }
  }

  .ng-doc-header {
    z-index: 220 !important;

    ng-doc-search ng-doc-icon {
      margin-left: var(--ng-doc-base-gutter);
    }
  }

  .ng-doc-navbar-right {
    ng-doc-icon,
    .feather {
      width: 20px !important;
      height: 20px !important;
    }
  }

  ul.ngde,
  ol.ngde {
    margin: 20px 0;
  }

  .ng-doc-demo {
    padding: 10px;
  }

  .ng-doc-copy-button {
    opacity: 1 !important;
    right: 16px !important;
    --ng-doc-transition: none;

    ng-doc-icon,
    ng-doc-icon .feather-copy {
      width: 24px !important;
      height: 24px !important;
    }
  }

  *:not(pre) > code.ngde {
    line-height: unset;
  }

  a.ngde {
    ng-doc-icon {
      vertical-align: text-top !important;
    }
  }

  ng-doc-page-wrapper {
    > .ngde:first-of-type {
      ng-doc-tab-routes-group {
        z-index: 10 !important;

        > a {
          text-align: center;
          width: 140px !important;
        }
      }
    }
  }

  // Hide constructor section under API tables
  .ng-doc-page-wrapper {
    > [id^="constructor"],
    > [id^="constructor"] + .ng-doc-table-wrapper {
      display: none;
    }
  }

  .ng-doc-toc-wrapper {
    .ng-doc-toc-selection {
      width: 3px !important;
    }

    .ng-doc-toc {
      border-left-width: 3px !important;
      --ng-doc-text: var(--ng-doc-sidebar-category-color);

      > li {
        letter-spacing: -0.01rem;
      }
    }
  }

  .ng-doc-tabs-wrapper {
    .ng-doc-tab {
      .ng-doc-tab-text {
        --ng-doc-font-weight: 500 !important;
      }
    }
  }

  .ng-doc-side-bar-wrapper {
    height: 100%;
  }

  .ng-doc-sidenav {
    z-index: 11 !important;
  }

  .ng-doc-sidenav-content {
    min-height: 300px;
    ng-doc-breadcrumb {
      margin-bottom: calc(var(--ng-doc-base-gutter) * 2.5);
    }
  }

  .ng-doc-sidebar-category-button {
    span {
      letter-spacing: -0.00875rem;
    }

    .ng-doc-sidebar-category {
      &,
      &.active {
        --ng-doc-font-weight: 500 !important;
      }
    }
  }

  .ng-doc-sidebar-link {
    --ng-doc-sidebar-vetical-padding: 0;
    --ng-doc-dot-background: var(--ng-doc-base-3);

    &.active {
      --ng-doc-text: var(--ng-doc-left-sidebar-link-active);
    }

    &:not(.active) {
      > ng-doc-dot {
        --ng-doc-dot-background: var(--ng-doc-base-3);
      }
    }

    > ng-doc-dot {
      width: 2.5px;
      height: 40px;
      border-radius: 0;
      z-index: unset !important;
    }
  }

  .ng-doc-table-wrapper > table {
    tbody,
    thead {
      > tr {
        > td {
          &:first-of-type {
            ng-doc-tooltip-wrapper {
              display: none;
            }
          }
        }

        > td, > th {
          padding: 12px;

          &:has(.ng-doc-no-content) {
            display: none;
          }

          &:nth-of-type(2) {
            max-width: 350px;

            code {
              text-wrap: wrap;
              word-wrap: break-word;
              font-family: monospace;
            }
          }

          code {
            line-height: var(--ng-doc-code-line-height, var(--ng-doc-line-height));
          }
        }
      }
    }
  }
  // END: ng-doc style overrides
}

:root[data-theme='light'] {
  --ng-doc-base-gutter: 8px;
  --ng-doc-transition: 0.2s;
  --ng-doc-text: var(--vermeer-charcoal);
  --ng-doc-heading-color: var(--ng-doc-text);
  --ng-doc-readonly-background: var(--vermeer-red);
  --ng-doc-text-selection: rgba(10, 104, 47, 0.15);
  --ng-doc-white: #fff;
  --ng-doc-black: #000;
  --ng-doc-base-0: var(--ng-doc-white);
  --ng-doc-base-1: #f6f6f6;
  --ng-doc-base-2: #eBeBeB;
  --ng-doc-base-3: #e1e1e1;
  --ng-doc-base-4: #c9c9c9;
  --ng-doc-base-5: #b0b0b0;
  --ng-doc-base-6: #a3a3a3;
  --ng-doc-base-7: #969696;
  --ng-doc-base-8: #898989;
  --ng-doc-base-9: #7d7d7d;
  --ng-doc-base-10: #666;
  --ng-doc-left-sidebar-link-active: var(--vermeer-green);
  --ng-doc-code-highlight-color: #209E56;
  --ng-doc-sidebar-category-color: #746e7c;
  --ng-doc-primary: var(--vermeer-green);
  --ng-doc-primary-text: var(--vermeer-white);
  --ng-doc-alert: var(--vermeer-red);
  --ng-doc-alert-text: var(--vermeer-white);
  --ng-doc-warning: var(--vermeer-accent);
  --ng-doc-warning-text: var(--vermeer-charcoal);
  --ng-doc-info: var(--vermeer-blue);
  --ng-doc-info-text: var(--vermeer-white);
  --ng-doc-background: var(--ng-doc-base-0);
  --ng-doc-hover-background: rgba(108, 108, 108, 0.12);
  --ng-doc-border-color: #dedede;
  --ng-doc-border-hover-color: var(--ng-doc-base-5);
  --ng-doc-shadow-color: rgba(0, 0, 0, 0.18);
  --ng-doc-code-background: #f6f6f6;
  --ng-doc-search-bar-background: rgba(142, 150, 170, .14);
  --ng-doc-inline-code-color: var(--vermeer-black);
  --ng-doc-code-font: var(--ng-doc-font-system-mono);
  --ng-doc-code-border: 1px solid var(--ng-doc-border-color);
  --ng-doc-text-muted: var(--ng-doc-base-8);
  --ng-doc-link-color: var(--vermeer-blue);
  --ng-doc-mark-background: rgba(255, 196, 37, 0.3);
  --ng-doc-nav-border: 1px solid var(--ng-doc-base-2);
  --ng-doc-button-background: trasparent;
  --ng-doc-button-background-opacity: 0;
  --ng-doc-button-color: var(--ng-doc-text);
  --ng-doc-button-hover-background: var(--ng-doc-base-1);
  --ng-doc-button-hover-color: var(--ng-doc-button-color);
  --ng-doc-button-active-background: var(--ng-doc-base-2);
  --ng-doc-button-active-color: var(--ng-doc-button-hover-color);
  --ng-doc-checkbox-color: var(--ng-doc-primary);
  --ng-doc-checkbox-color-text: var(--ng-doc-primary-text);
  --ng-doc-checkbox-border: var(--ng-doc-border-size) solid var(--ng-doc-border-color);
  --ng-doc-checkbox-border-hover: var(--ng-doc-border-size) solid var(--ng-doc-border-hover-color);
  --ng-doc-input-text-align: left;
  --ng-doc-input-border: var(--ng-doc-border-size) solid var(--ng-doc-border-color);
  --ng-doc-input-border-hover: var(--ng-doc-border-size) solid var(--ng-doc-border-hover-color);
  --ng-doc-input-background-color: var(--ng-doc-base-0);
  --ng-doc-input-padding-left: calc(var(--ng-doc-base-gutter) * 2);
  --ng-doc-input-padding-right: calc(var(--ng-doc-base-gutter) * 2);
  --ng-doc-input-cursor: auto;
  --ng-doc-tag-background: var(--ng-doc-primary);
  --ng-doc-tag-color: var(--ng-doc-primary-text);
  --ng-doc-selection-border: 3px solid var(--ng-doc-left-sidebar-link-active);
  --ng-doc-selection-background: transparent;
  --ng-doc-dot-background: var(--ng-doc-primary);
  --ng-doc-tab-group-border-radius: var(--vermeer-border-radius);
  --ng-doc-tab-group-border: 1px solid var(--ng-doc-border-color);
}
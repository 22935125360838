@use '@angular/material' as mat;
@use '../../../../libs/it-ng-components/core/theming/palettes';
@use '../../../../libs/it-ng-components/core/theming/all-theme' as vmr;
@use '../../../../libs/it-ng-components/core/style/vars' as vars;
@use '../../../../libs/it-ng-components/core/style/form-common';

@use 'resets';
@use 'docs/icon';

@mixin root-definitions() {
  --vermeer-white: white;
  --vermeer-red: #d60000;
  --vermeer-blue: #005cc5;
  --vermeer-light-blue: #E6F1F5;
  --vermeer-accent: #ff9f1a;
  --vermeer-charcoal: #272828;
  --vermeer-black: #101010;
  --vermeer-green: #0a682f;
  --vermeer-secondary-green: #06843c;
  --vermeer-border-radius: 2px;
  --vermeer-font: 'Roboto', Arial, sans-serif;
  --vermeer-font-header: 'Roboto Condensed', sans-serif;
  --vermeer-font-code: 'DM Mono', monospace;

  // Override ng-doc
  --ng-doc-dot-size: 5px;
  --ng-doc-border-size: 1px;
  --ng-doc-input-width: 100%;
  --ng-doc-input-height: 40px;
  --ng-doc-sidebar-width: 275px;
  --ng-doc-font-weight: 400;
  --ng-doc-code-font-size: 14px;
  --ng-doc-code-font-weight: 400;
  --ng-doc-code-line-height: 20px;
  --ng-doc-code-border-radius: 4px;
  --ng-doc-floated-border-color: transparent;
  --ng-doc-font-system: var(--vermeer-font);
  --ng-doc-font-system-mono: var(--vermeer-font-code);
  --ng-doc-heading-font-family: var(--ng-doc-font-system);
  --ng-doc-font-family: var(--ng-doc-font-system);
  --ng-doc-font-size: calc(var(--ng-doc-base-gutter) * 2);
  --ng-doc-line-height: calc(var(--ng-doc-base-gutter) * 3);
  --ng-doc-left-sidebar-link-active: #{vars.$vmr-secondary-green};
  --ng-doc-floated-border: 2px solid var(--ng-doc-floated-border-color);
  --ng-doc-floated-border-shadow-color: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px transparent;
  --page-background: var(--vermeer-white);
}

@mixin dark-mode-definitions() {
  --page-background: #1D1D1D;
  --ng-doc-code-background: #292E33;
  --ng-doc-primary: #{vars.$vmr-yellow};
  --shiki-dark-bg: var(--ng-doc-code-background);

  .shiki {
    color: var(--shiki-dark);
    background-color: var(--shiki-dark-bg);
  }

  ng-doc-tab-group > .ng-doc-tabs-wrapper,
  ng-doc-page-wrapper > ng-doc-demo > ng-doc-demo-displayer {
    --ng-doc-tab-group-tabs-background: #31363B !important;
  }
}

// Init material core styles first
@include mat.core();

// Vermeer light theme
$vmr-light-primary: mat.m2-define-palette(palettes.$vmr-light-primary-palette);
$vmr-light-accent: mat.m2-define-palette(palettes.$vmr-light-accent-palette);
$vmr-light-warn: mat.m2-define-palette(palettes.$vmr-light-warn-palette);

$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $vmr-light-primary,
      accent: $vmr-light-accent,
      warn: $vmr-light-warn
    ),
    typography: mat.m2-define-typography-config($font-family: vars.$vmr-fonts-base),
    density: 0
  )
);

// Vermeer dark theme
// TODO: Refactor this setup and update CSS vars when provided by UX
$dark-primary: mat.m2-define-palette(mat.$m2-cyan-palette);
$dark-accent: mat.m2-define-palette(mat.$m2-orange-palette);
$dark-warn: $vmr-light-warn;

$dark-theme: mat.m2-define-dark-theme((
  color: (
    primary: $dark-primary,
    accent: $dark-accent,
    warn: $dark-warn
  )
));

// LIGHT MODE IS DEFAULT (apply structural styles globally and override colors for dark theme)
// TODO: need to add an "all-component-colors" sass function to our lib
@include mat.all-component-themes($light-theme);
@include vmr.all-vmr-component-themes($light-theme);

.vmr-light-mode {
  @include root-definitions();

  .ng-doc-sidebar-link,
  .ng-doc-sidebar-category {
    --ng-doc-text: var(--ng-doc-sidebar-category-color);
  }

  ng-doc-code[data-ng-doc-has-header='true'] {
    --ng-doc-code-border-radius: 0 0 4px 4px !important;
  }

  ng-doc-demo-displayer {
    --ng-doc-demo-displayer-border: 1px solid var(--ng-doc-border-color);
  }

  ng-doc-search {
    --ng-doc-input-background-color: var(--ng-doc-search-bar-background) !important;
  }
}

// DARK MODE (Explicit)
.vmr-dark-mode {
  @include root-definitions();
  @include dark-mode-definitions();

  // This mixin only generates the color styles now.
  @include mat.all-component-colors($dark-theme);
  @include vmr.all-vmr-component-themes($dark-theme);
  // background-color: var(--page-background);

  .docs-logo,
  .demo-logo-horizontal,
  .demo-logo-vertical {
    .vmr-logo,
    .vmr-logo-vertical {
      svg g {
        fill: var(--ng-doc-heading-color);
      }
    }
  }

  // TODO: update _loader-theme.scss
  .vmr-loader {
    --vmr-loader-backdrop-bg-color: rgba(253, 251, 255, 0.12) !important;
  }

  // TODO: move to _theme-toggle-theme.scss
  .vmr-theme-toggle-button svg {
    color: var(--ng-doc-text);
  }

  // TODO: move to _skeleton-theme.scss
  .vmr-skeleton-container {
    background: #272520;

    &.vmr-skeleton-progress::after {
      background: linear-gradient(
        90deg, rgba(43, 43, 43, 0),
        rgba(43, 43, 43, 1),
        rgba(43, 43, 43, 0)
      );
    }
  }
}

:root[data-theme='dark'] {
  --ng-doc-primary: #{vars.$vmr-yellow};
}

// Include global styles + ng-doc styles overrides
@include resets.resets();

// Include custom docs-app styles
@include icon.docs-icon();

// Include mixin that formats form-field for vermeer
@include form-common.normalize-non-textarea-form-fields();